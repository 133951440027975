<template>
  <el-button
    plain
    :disabled="disabled"
    @click="$emit('duplicate-requested')"
    v-text="$t('pages.pricebooks.all.duplicate_button')"
  />
</template>

<script>
export default {
  props: {
    selectedItems: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    buttonText() {
      //pluralize the button text if more than one item is selected
      //by default, the button text is singular
      return this.$t(
        'pages.pricebooks.all.duplicate_button',
        this.selectedItems.length || 1
      )
    },
    disabled() {
      return this.selectedItems.length === 0
    }
  }
}
</script>
