<template>
  <th-page-wrapper>
    <th-datatable
      ref="pricebooksTable"
      :key="$route.fullPath"
      v-loading="isLoading"
      do-route
      do-route-filters
      no-meta-check
      headers-filterable
      route-base="/products/pricebooks"
      show-search
      multiple-select
      :multiple-select-limit="multipleSelectLimit"
      :buttons="computedButtons"
      :custom-resource="customResource"
      :headers-config="headersConfig"
      :headers="headers"
      :locale="locale"
      :meta-options="resourceQuery"
      :resource-query="{ query: resourceQuery }"
      :resource="false"
      :show-operations="false"
      @headers-config="handleHeadersConfig"
      @loading-error="handleLoadingError"
      @selection-change="selectedItems = $event"
    >
      <template #actions class="actions">
        <pricebook-actions
          :selected-items="selectedItems"
          @duplicate-requested="handleDuplication"
        />
      </template>
    </th-datatable>
  </th-page-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import PricebookActions from './components/pricebook-actions'

const headersConfigPath = 'settings.headerFilters.pricebooks.list'
const multipleSelectLimit = 5 //this is limited by the backend

export default {
  name: 'PricebookAll',
  metaInfo() {
    return {
      title: this.$t('pages.pricebooks.title')
    }
  },
  components: {
    PricebookActions
  },
  data() {
    return {
      multipleSelectLimit,
      selectedItems: [],
      allSelected: false,
      isLoading: false,
      headers: [
        {
          field: 'name',
          label: this.$t('pages.pricebooks.all.headers.name'),
          minWidth: 160,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'created_at',
          label: this.$t('pages.pricebooks.all.headers.created_at'),
          minWidth: 100,
          truncate: true,
          formatter: (row, column) => {
            const date = safeGet(row, 'created_at.iso')
            return date ? this.$date.formatDateWithTimezone(date) : '--'
          }
        },
        {
          field: 'updated_at',
          label: this.$t('pages.pricebooks.all.headers.updated_at'),
          fallback: '-',
          minWidth: 100,
          truncate: true,
          formatter: (row, column) => {
            const date = safeGet(row, 'updated_at.iso')
            return date ? this.$date.formatDateWithTimezone(date) : '--'
          }
        },
        {
          field: 'constraints',
          label: this.$t('pages.pricebooks.all.headers.constraints'),
          fallback: '-',
          minWidth: 160,
          truncate: true,
          formatter: (row, column) => {
            const { start, end } =
              safeGet(row, 'constraints.time.scheduled.date_range') || {}
            if (!start || !start.enabled || !start.value) return '--'
            const startDate = this.$date.formatDateWithTimezone(start.value)
            let response = startDate
            if (end && end.enabled && end.value) {
              const endDate = this.$date.formatDateWithTimezone(end.value)
              response += ` - ${endDate}`
            }
            return response
          }
        }
      ],
      buttons: [
        {
          type: 'create',
          scopes: ['products:pricebooks:create']
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      locale: 'Config/getLocale',
      clientAccountConfiguration: 'Config/getClientAccountConfiguration',
      localConfiguration: 'Config/getLocalConfiguration',
      currentLocation: 'Config/getCurrentLocation'
    }),
    resourceQuery() {
      return {
        deleted: false,
        branch: this.currentLocation
      }
    },
    headersConfig() {
      return safeGet(this.localConfiguration, headersConfigPath, {})
    },
    timeZone() {
      return safeGet(
        this.clientAccountConfiguration,
        'settings.default_timezone',
        'Europe/Berlin'
      )
    },
    customResource() {
      return th.products().pricebooks()
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },
  watch: {
    currentLocation() {
      this.refresh()
    },
    selectedItems() {
      if (this.selectedItems.length > this.multipleSelectLimit) {
        this.showSelectedLimitExceededWarning()
      }
    }
  },
  methods: {
    refresh() {
      this.$refs.pricebooksTable.refresh()
    },
    handleHeadersConfig(config) {
      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: headersConfigPath,
        value: config || {}
      })
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.pricebooks.title')
        })
      })
    },
    async handleDuplication() {
      if (this.selectedItems.length > this.multipleSelectLimit) {
        this.showSelectedLimitExceededWarning()
      } else {
        await this.duplicate()
      }
    },
    async duplicate() {
      try {
        this.isLoading = true
        //confirm duplication before sending requests
        await this.$confirm(
          this.$t(
            'pages.pricebooks.all.duplicate_confirmation',
            {
              name: this.selectedItems[0]?.name
            },
            this.selectedItems.length
          ),
          {
            confirmButtonText: this.$t('common.interactions.buttons.ok'),
            cancelButtonText: this.$t('common.interactions.buttons.cancel')
          }
        )

        await this.customResource.copy({
          ids: this.selectedItems.map(({ id }) => id)
        })

        //refresh table after item duplication
        this.$refs.pricebooksTable.refresh()
      } catch (err) {
        this.$logException(err, {
          message: err.message,
          trackError: false
        })
      } finally {
        this.isLoading = false
      }
    },
    showSelectedLimitExceededWarning() {
      //show a warning message if the user tries to select more than the limit
      this.$message.warning(
        this.$t('pages.pricebooks.all.multiple_select_warning', {
          limit: this.multipleSelectLimit
        })
      )
    }
  }
}
</script>

<style scoped></style>
